.step[data-v-08aa6b0c] {
  background: #fff;
  padding: 12px;
  margin-bottom: 12px;
}
[data-v-08aa6b0c] .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}
[data-v-08aa6b0c] .el-step__main {
  cursor: pointer;
}